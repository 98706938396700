import image from '../assets/webhero.webp'

const FormComponent = () => {

  return (
    <div data-aos="fade-down" data-aos-duration="1000" data-aos-offset="200">
    <img 
      src={image} className='img-fluid mx-auto vw-100' alt="9th Nov 2024 Janessa & Po's wedding at Raffles Hotel Lawn"></img>
      {/* </div> */}
    <div 
     className="col-md-9 mx-auto">
        <div className='row d-flex justify-content-center'>
          <p className='newsreader fs-1 text-center my-5'>
           Hey there! We are starting a <i>new chapter</i> in our story, <br/>we invite you to join our wedding celebration.
          </p>
        </div>

        <div className='bg-light d-flex justify-content-center p-3'>
          <br/>
          <div className='row fs-5 my-4'>
            <div className='col-8 m-3 mx-auto'>
            <div className='row'>
              <div className='col-sm-3 mb-1 fw-bold'>Date</div>
              <div className='col-sm-9 mb-1'>09 November 2024, Saturday</div>
              <div className='col-sm-3 mb-1 fw-bold'>Time</div>
              <div className='col-sm-9 mb-1'>4pm - 6pm (Please be seated by 4:15pm)</div>
              <div className='col-sm-3 mb-1 fw-bold'>Venue</div>
              <div className='col-sm-9 mb-1'>Raffles Hotel Lawn 🌳</div>
            </div>
            </div>
          </div>
          <br/>
        </div>

    <br/>
    {/* <p className='text-center fs-6'> If there’s any issue with the form submission, please drop Po or Janessa a text!</p> */}
    </div>
    </div>
  );
};

export default FormComponent;
