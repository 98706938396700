import './App.css';
import './style.css';
// import FormComponent from './components/FormField';
import FormPage from './views/FormPage.js'
// import FAQs from './components/FAQ';
import FAQPage from './views/FAQPage.js';
import Layout from './Layout.js';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import PhotoWall from './views/PhotoWall.js'

import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';

function App() {
    useEffect(() => {
      AOS.init({ duration: 1000 });
  }, []);

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<PhotoWall/>}/>
        <Route path="/FAQ" element={<FAQPage />}/>
        <Route path="/info" element={<FormPage/>}/>
        <Route path="/*" element={<FormPage />}/>
      </Route>
    </Routes>
    
    </BrowserRouter>
    

  );
}

export default App;
