
// import { ref } from 'vue'
import FormComponent from '../components/FormField'

const FormPage= () => {
  return (
    <div>
      <FormComponent style='width: 100%'/ >
    </div>
  )
}

export default FormPage
